import { Link } from 'react-router-dom';
import style from './style.module.css';

function Agreement() {
    
  return (
    <section className={style.wrapper}>
      <Link className={style.link} to='/'>Вернуться назад</Link>
      <h2 className={style.title}>// ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h2>
      <h3 className={style.item}>Термины и&nbsp;определения, используемые в&nbsp;настоящем соглашение</h3>
      <p className={style.text}>Перечисленные ниже термины в&nbsp;рамках настоящего Соглашения имеют следующее значение:</p>
      <ul className={style.list}>
        <li className={style.text}>Сайт&nbsp;&mdash; совокупность всей информации (текстовой и&nbsp;графической), находящаяся 
          в&nbsp;сети интернет по&nbsp;адресу www.les-sib-rf.ru, принадлежащая ИП&nbsp;Трофимов А.А. 
          (Далее&nbsp;ИП).
        </li>
        <li className={style.text}>Администрация Сайта&nbsp;&mdash; уполномоченные лица на&nbsp;внесение изменений информации 
          на&nbsp;Сайте.
        </li>
        <li className={style.text}>Пользователь Сайта (далее&nbsp;&mdash; Пользователь)&nbsp;&mdash; лицо, использующее Сайт&nbsp;ИП.</li>
        <li className={style.text}>Стороны&nbsp;&mdash; ИП&nbsp;и&nbsp;Пользователь, заключившие Настоящее Соглашение.</li>
      </ul>
      <h3 className={style.item}>ОБЩИЕ ПОЛОЖЕНИЯ</h3>
      <ol className={style.list}>
        <li className={style.text}>Настоящее Пользовательское соглашение (далее&nbsp;&mdash; Соглашение) относится к&nbsp;Сайту ИП 
          расположенному в&nbsp;сети интернет по&nbsp;адресу www.les-sib-rf.ru.
        </li>
        <li className={style.text}>Сайт является собственностью&nbsp;ИП.</li>
        <li className={style.text}>Настоящее Соглашение регулирует отношения между&nbsp;ИП и&nbsp;Пользователем.</li>
        <li className={style.text}>ИП&nbsp;оставляет за&nbsp;собой право в&nbsp;любое время вносить изменения в&nbsp;текст настоящего 
          Соглашения без уведомления Пользователя.
        </li>
        <li className={style.text}>Продолжение использования Сайта Пользователем означает принятие условий Соглашения и&nbsp;всех 
          изменений к&nbsp;нему, внесенных в&nbsp;настоящее Соглашение.
        </li>
        <li className={style.text}>Пользователь несет персональную ответственность за&nbsp;проверку настоящего Соглашения на&nbsp;наличие 
          изменений в&nbsp;нем.
        </li>
        <li className={style.text}>Соглашение вступает в&nbsp;силу с&nbsp;момента выражения Пользователем согласия с&nbsp;его условиями, 
          начиная просмотр Сайта, либо пройдя процедуру регистрации, Пользователь считается принявшим условия 
          Соглашения в&nbsp;полном объеме.
        </li>
      </ol>
      <h3 className={style.item}>ПРЕДМЕТ СОГЛАШЕНИЯ</h3>
      <ol className={style.list}>
        <li className={style.text}>Предметом настоящего Соглашения является предоставление Пользователю доступа к&nbsp;информации, и&nbsp;онлайн 
          сервисам, представленным на&nbsp;Сайте.
        </li>
        <li className={style.text}>Сайт предоставляет Пользователю следующие виды сервисов:</li>
        <ul className={style.list}>
          <li className={style.text}>свободный доступ к&nbsp;информации (текстовой и&nbsp;графической) с&nbsp;правом просмотра данной 
            информации;
          </li>
          <li className={style.text}>доступ к&nbsp;средствам поиска и&nbsp;навигации Сайта;</li>
          <li className={style.text}>предоставление Пользователю возможности запросов стоимости, услуг и&nbsp;иной информации (обратная 
            связь);
          </li>
          <li className={style.text}>доступ к&nbsp;информации об&nbsp;оказываемых услугах;</li>
          <li className={style.text}>и&nbsp;иное.</li>
        </ul>
        <li className={style.text}>Под действие настоящего Соглашения подпадают все существующие на&nbsp;данный момент сервисы Сайта, 
          а&nbsp;также любые их&nbsp;последующие модификации и&nbsp;появляющиеся в&nbsp;дальнейшем сервисы.
        </li>
        <li className={style.text}>Доступ к&nbsp;Сайту предоставляется Пользователю на&nbsp;безвозмездной основе.</li>
        <li className={style.text}>Настоящее Соглашение является публичной офертой. Получая доступ к&nbsp;Сайту, Пользователь соглашается 
          с&nbsp;условиями настоящего Соглашения и&nbsp;считается присоединившимся к&nbsp;настоящему Соглашению.
        </li>
        <li className={style.text}>Использование материалов и&nbsp;сервисов Сайта регулируется нормами действующего законодательства 
          Российской Федерации.
        </li>
      </ol>
      <h3 className={style.item}>ПРАВА И&nbsp;ОБЯЗАННОСТИ СТОРОН</h3>
      <p className={style.text}>ИП&nbsp;вправе:</p>
      <ol className={style.list}>
        <li className={style.text}>Изменять правила пользования Сайтом, а&nbsp;также изменять содержание Сайта. Изменения вступают 
          в&nbsp;силу с&nbsp;момента публикации новой редакции Соглашения на&nbsp;Сайте.
        </li>
        <li className={style.text}>Ограничить Пользователю доступ к&nbsp;Сайту или к&nbsp;частям сайта в&nbsp;случае нарушения 
          Пользователем условий настоящего Соглашения.
        </li>
      </ol>
      <p className={style.text}>Пользователь вправе:</p>
      <ol className={style.list}>
        <li className={style.text}>Получить доступ к&nbsp;использованию Сайта в&nbsp;ознакомительных и&nbsp;подобных целях, а&nbsp;также 
          к&nbsp;онлайн сервисам, представленным на&nbsp;Сайте.
        </li>
        <li className={style.text}>Задавать любые вопросы, относящиеся к&nbsp;услугам, представленным на&nbsp;Сайте и&nbsp;иной информации, 
          размещенной на&nbsp;Сайте, используя раздел Сайта &laquo;Контакты&raquo;.
        </li>
        <li className={style.text}>Пользоваться Сайтом исключительно в&nbsp;целях и&nbsp;порядке, предусмотренных Соглашением.</li>
      </ol>
      <p className={style.text}>Пользователь Сайта обязуется:</p>
      <ol className={style.list}>
        <li className={style.text}>Соблюдать имущественные и&nbsp;неимущественные права авторов и&nbsp;иных правообладателей при 
          использовании Сайта.
        </li>
        <li className={style.text}>Не&nbsp;предпринимать никаких действий, которые могут рассматриваться как нарушающие нормальную 
          работу Сайта.
        </li>
        <li className={style.text}>Не&nbsp;распространять с&nbsp;использованием Сайта любую конфиденциальную и&nbsp;охраняемую 
          законодательством Российской Федерации информацию о&nbsp;физических или юридических лицах.
        </li>
        <li className={style.text}>Избегать любых действий, в&nbsp;результате которых может быть нарушена конфиденциальная информация, 
          охраняемая законодательством Российской Федерации.
        </li>
        <li className={style.text}>Не&nbsp;использовать Сайт для распространения информации рекламного характера.</li>
        <li className={style.text}>Не&nbsp;использовать сервисы Сайта с&nbsp;целью:</li>
        <ul className={style.list}>
          <li className={style.text}>Загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует 
            насилие, жестокость, ненависть&nbsp;и (или) дискриминацию по&nbsp;расовому, национальному, половому, 
            религиозному, социальному признакам; содержит недостоверные сведения&nbsp;и (или) оскорбления 
            в&nbsp;адрес конкретных лиц, организаций, органов власти.
          </li>
          <li className={style.text}>Побуждения к&nbsp;совершению противоправных действий, а&nbsp;также содействия лицам, действия 
            которых направлены на&nbsp;нарушение ограничений и&nbsp;запретов, действующих на&nbsp;территории 
            Российской Федерации.
          </li>
          <li className={style.text}>Нарушения прав несовершеннолетних лиц&nbsp;и (или) причинение им&nbsp;вреда в&nbsp;любой форме.</li>
          <li className={style.text}>Представления себя за&nbsp;другого человека или представителя организации&nbsp;и (или) сообщества 
            без достаточных на&nbsp;то&nbsp;прав, в&nbsp;том числе за&nbsp;Администрацию Сайта.
          </li>
          <li className={style.text}>Введения в&nbsp;заблуждение относительно свойств и&nbsp;характеристик какой-либо услуги, размещенной 
            на&nbsp;Сайте.
          </li>
          <li className={style.text}>Некорректного сравнения услуги, а&nbsp;также формирования негативного отношения к&nbsp;лицам, 
            пользующимся/не пользующимися определенными услугами, или осуждения таких лиц.
          </li>
        </ul>
      </ol>
      <p className={style.text}>Пользователю запрещается:</p>
      <ol className={style.list}>
        <li className={style.text}>Нарушать надлежащее функционирование Сайта.</li>
        <li className={style.text}>Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой 
          информации, документов или материалов любыми средствами, которые специально не&nbsp;представлены 
          сервисами Сайта.
        </li>
        <li className={style.text}>Несанкционированный доступ к&nbsp;функциям Сайта, любым другим системам или сетям, относящимся 
          к&nbsp;данному Сайту, а&nbsp;также к&nbsp;любым услугам, предлагаемым на&nbsp;Сайте.
        </li>
        <li className={style.text}>Нарушать систему безопасности или аутентификации на&nbsp;Сайте.</li>
        <li className={style.text}>Отслеживать или пытаться отслеживать любую информацию о&nbsp;любом другом Пользователе Сайта.</li>
        <li className={style.text}>Использовать Сайт в&nbsp;любых целях, запрещенных законодательством Российской Федерации, 
          а&nbsp;также подстрекать к&nbsp;любой незаконной деятельности или другой деятельности, нарушающей 
          права&nbsp;ИП или других лиц.
        </li>
      </ol>
      <h3 className={style.item}>ИСПОЛЬЗОВАНИЕ САЙТА</h3>
      <ol className={style.list}>
        <li className={style.text}>Сайт не&nbsp;может быть скопирован, опубликован, воспроизведен, передан или распространен любым 
          способом, полностью или частично, без письменного согласия правообладателя.
        </li>
        <li className={style.text}>Сайт защищен авторским правом, а&nbsp;также другими правами, связанными с&nbsp;интеллектуальной 
          собственностью.
        </li>
        <li className={style.text}>Консультация через Сайте требует внесения своих персональных данных на&nbsp;Сайте.</li>
        <li className={style.text}>Пользователь несет персональную ответственность за&nbsp;сохранение конфиденциальности информации, 
          а&nbsp;также за&nbsp;всю без исключения деятельность, которая ведётся от&nbsp;имени Пользователя.
        </li>
        <li className={style.text}>Настоящее Соглашение распространяет свое действия на&nbsp;все дополнительные положения и&nbsp;условия 
          по&nbsp;оказанию услуг, предоставляемых на&nbsp;Сайте.
        </li>
        <li className={style.text}>Информация, размещаемая на&nbsp;Сайте не&nbsp;должна истолковываться как изменение настоящего Соглашения.</li>
        <li className={style.text}>Администрация сайта имеет право в&nbsp;любое время без уведомления Пользователя вносить изменения 
          в&nbsp;перечень услуг, предлагаемых на&nbsp;Сайте, а&nbsp;также изменять в&nbsp;цены, применимые 
          к&nbsp;оказываемым услугам при их&nbsp;наличии на&nbsp;Сайте.
        </li>
      </ol>
      <h3 className={style.item}>ОТВЕТСТВЕННОСТЬ</h3>
      <ol className={style.list}>
        <li className={style.text}>Любые убытки, которые Пользователь может понести в&nbsp;случае умышленного или неосторожного нарушения 
          любого положения настоящего Соглашения, а&nbsp;также вследствие несанкционированного доступа 
          к&nbsp;коммуникациям другого Пользователя, ИП&nbsp;не&nbsp;возмещаются.
        </li>
        <li className={style.text}>Ненадлежащее функционирование Сайта, в&nbsp;случае, если Пользователь не&nbsp;имеет необходимых 
          технических средств для его использования, а&nbsp;также не&nbsp;несет никаких обязательств 
          по&nbsp;обеспечению пользователей такими средствами.
        </li>
      </ol>
      <h3 className={style.item}>НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</h3>
      <ol className={style.list}>
        <li className={style.text}>ИП&nbsp;вправе раскрыть любую собранную о&nbsp;Пользователе Сайта информацию, если раскрытие 
          необходимо в&nbsp;связи с&nbsp;расследованием или жалобой в&nbsp;отношении неправомерного использования 
          Сайта либо для установления (идентификации) Пользователя, который может нарушать или вмешиваться 
          в&nbsp;права&nbsp;ИП или иных лиц.
        </li>
        <li className={style.text}>ИП&nbsp;имеет право раскрыть информацию о&nbsp;Пользователе, если действующее законодательство 
          Российской Федерации требует или разрешает такое раскрытие.
        </li>
        <li className={style.text}>ИП&nbsp;вправе без предварительного уведомления Пользователя прекратить доступ к&nbsp;Сайту, если 
          Пользователь нарушил настоящее Соглашение или содержащиеся в&nbsp;иных документах условия пользования 
          Сайтом, а&nbsp;также в&nbsp;случае прекращения действия Сайта либо по&nbsp;причине технической неполадки.
        </li>
        <li className={style.text}>ИП&nbsp;не&nbsp;несет ответственности перед Пользователем или третьими лицами за&nbsp;прекращение 
          доступа к&nbsp;Сайту в&nbsp;случае нарушения Пользователем любого положения настоящего Соглашения или 
          иного документа, содержащего условия пользования Сайтом.
        </li>
      </ol>
      <h3 className={style.item}>РАЗРЕШЕНИЕ СПОРОВ</h3>
      <ol className={style.list}>
        <li className={style.text}>В&nbsp;случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения 
          обязательным условием до&nbsp;обращения в&nbsp;суд является досудебный порядок в&nbsp;соответствие 
          с&nbsp;действующим законодательством.
        </li>
        <li className={style.text}>Получатель претензии в&nbsp;течение 30&nbsp;календарных дней со&nbsp;дня ее&nbsp;получения, должен 
          письменно уведомляет заявителя претензии о&nbsp;результатах рассмотрения претензии.
        </li>
        <li className={style.text}>При невозможности разрешить спор в&nbsp;добровольном порядке любая из&nbsp;Сторон вправе обратиться 
          в&nbsp;суд за&nbsp;защитой своих прав, которые предоставлены им&nbsp;действующим законодательством 
          Российской Федерации.
        </li>
      </ol>
    </section>
  );
}
  
export default Agreement;