import item1 from '../images/slider/item1.png';
import item2 from '../images/slider/item2.png';
import item3 from '../images/slider/item3.png';
import item4 from '../images/slider/item4.png';
import item5 from '../images/slider/item5.png';
import item6 from '../images/slider/item6.png';
import item7 from '../images/slider/item7.png';
import item8 from '../images/slider/item8.png';
import item9 from '../images/slider/item9.png';
import item10 from '../images/slider/item10.png';
import item11 from '../images/slider/item11.png';
import item12 from '../images/slider/item12.png';
import item13 from '../images/slider/item13.png';
import item14 from '../images/slider/item14.png';
import item15 from '../images/slider/item15.png';
import item16 from '../images/slider/item16.png';
import item17 from '../images/slider/item17.png';
import item18 from '../images/slider/item18.png';
import item19 from '../images/slider/item19.png';

const items = [item1, item2, item3, item4, item5, item6, item7, item8, item9, item10, item11, item12, item13, item14, item15, item16, item17, item18, item19];

export default items;