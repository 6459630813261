import { Link } from 'react-router-dom';
import style from './style.module.css';

function Policy() {
    
return (
  <section className={style.wrapper}>
    <Link className={style.link} to='/'>Вернуться назад</Link>
    <h2 className={style.title}>// ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
    <h3 className={style.item}>ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h3>
    <p className={style.text}>В&nbsp;настоящей Политике конфиденциальности используются следующие термины:</p>
    <p className={style.text}>Сайт&nbsp;&mdash; совокупность всей информации (текстовой и&nbsp;графической), находящаяся в&nbsp;сети интернет 
      по&nbsp;адресу www.les-sib-rf.ru, принадлежащая ИП&nbsp;Трофимов&nbsp;А.А. &laquo;Лес Сибири&raquo; (Далее ИП).
    </p>
    <p className={style.text}>Администрация Сайта&nbsp;&mdash; уполномоченные лица на&nbsp;внесение изменений информации на&nbsp;Сайте.</p>
    <p className={style.text}>Пользователь Сайта (далее&nbsp;&mdash; Пользователь)&nbsp;&mdash; лицо, использующее Сайт ИП.</p>
    <p className={style.text}>Стороны&nbsp;&mdash; ИП и&nbsp;Пользователь, заключившие Настоящее Соглашение.</p>
    <p className={style.text}>Персональные данные&nbsp;&mdash; любая информация, относящаяся к&nbsp;прямо или косвенно определенному, 
      или определяемому физическому лицу (субъекту персональных данных).
    </p>
    <p className={style.text}>Обработка персональных данных&nbsp;&mdash; любое действие (операция) или совокупность действий (операций), 
      совершаемых с&nbsp;использованием средств автоматизации или без использования таких средств 
      с&nbsp;персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение 
      (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), 
      обезличивание, блокирование, удаление, уничтожение персональных данных.
    </p>
    <p className={style.text}>Конфиденциальность персональных данных&nbsp;&mdash; обязательное для соблюдения Оператором или иным получившим 
      доступ к&nbsp;персональным данным лицом требование не&nbsp;допускать их&nbsp;распространения без согласия 
      субъекта персональных данных или наличия иного законного основания.
    </p>
    <p className={style.text}>Cookies&nbsp;&mdash; технические данные, отправляемые Сайтом Пользователю через сеть интернет, хранящиеся 
      на&nbsp;устройстве Пользователя, которое использовано для просмотра Сайта и&nbsp;передаваемые на&nbsp;Сайт 
      с&nbsp;устройства Пользователя, использованного для просмотра Сайта, при каждом посещении Сайта.
    </p>
    <p className={style.text}>IP-адрес&nbsp;&mdash; технический адрес Пользователя в&nbsp;сети интернет.</p>
    <h3 className={style.item}>ОБЩИЕ ПОЛОЖЕНИЯ</h3>
    <ol className={style.list}>
      <li className={style.text}>Настоящая Политика конфиденциальности персональных данных (далее&nbsp;&mdash; Политика конфиденциальности) 
        действует в&nbsp;отношении всей информации, которую Сайт может получить о&nbsp;Пользователе, во&nbsp;время 
        использования последним Сайта и&nbsp;его сервисов.
      </li>
      <li className={style.text}>Использование Пользователем Сайта означает согласие с&nbsp;настоящей Политикой конфиденциальности 
        и&nbsp;условиями обработки персональных данных Пользователя.
      </li>
      <li className={style.text}>В случае несогласия с условиями Политики конфиденциальности Пользователь обязан прекратить использование 
        Сайта.
      </li>
      <li className={style.text}>Настоящая Политика конфиденциальности непосредственно применяется только к&nbsp;Сайту. 
        ИП&nbsp;не&nbsp;контролирует и&nbsp;не&nbsp;несет ответственность за&nbsp;сайты третьих лиц, на&nbsp;которые 
        Пользователь может перейти по&nbsp;ссылкам, доступным на&nbsp;Сайте.
      </li>
      <li className={style.text}>ИП&nbsp;не&nbsp;проверяет достоверность персональных данных, предоставляемых Пользователем Сайта.</li>
    </ol>
    <h3 className={style.item}>ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</h3>
    <ol className={style.list}>
      <li className={style.text}>Настоящая Политика конфиденциальности устанавливает обязательства&nbsp;ИП по&nbsp;неразглашению 
        и&nbsp;обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет 
        при регистрации на&nbsp;Сайте.
      </li>
      <li className={style.text}>Персональные данные, разрешённые к&nbsp;обработке в&nbsp;рамках настоящей Политики конфиденциальности, 
        предоставляются Пользователем путём заполнения регистрационной формы на&nbsp;Сайте по&nbsp;адресу 
        www.les-sib-rf.ru и&nbsp;включают в&nbsp;себя следующую информацию:
        </li>
        <ul className={style.list}>
          <li className={style.text}>Имя Пользователя;</li>
          <li className={style.text}>контактный телефон Пользователя;</li>
          <li className={style.text}>адрес электронной почты (e-mail).</li>
        </ul>
        <li className={style.text}>Отключение Cookies может повлечь невозможность доступа к&nbsp;Сайту.</li>
        <li className={style.text}>Сайт осуществляет сбор IP-адресов Пользователей.</li>
        <li className={style.text}>Любая иная персональная информация, не&nbsp;оговоренная выше подлежит надежному хранению 
          и&nbsp;нераспространению, за&nbsp;исключением случаев, предусмотренных в&nbsp;настоящей Политики конфиденциальности.
        </li>
    </ol>
    <h3 className={style.item}>ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</h3>
    <p className={style.text}>ИП&nbsp;может использовать персональные данные Пользователя Сайта в&nbsp;целях:</p>
    <ol className={style.list}>
      <li className={style.text}>Установления с&nbsp;Пользователем обратной связи, включая направление уведомлений, запросов, касающихся 
        использования Сайта, оказания услуг, оплаты, обработки запросов и&nbsp;заявок от&nbsp;Пользователя.
      </li>
      <li className={style.text}>Предоставления Пользователю клиентской и&nbsp;технической поддержки при возникновении проблем, связанных 
        с&nbsp;использованием Сайта.
      </li>
      <li className={style.text}>Предоставления Пользователю, с&nbsp;его согласия, обновлений ассортимента услуг, специальных предложений, 
        информации о&nbsp;ценах, новостной рассылки и&nbsp;иных сведений от&nbsp;имени&nbsp;ИП или от&nbsp;имени 
        партнеров&nbsp;ИП.
      </li>
      <li className={style.text}>Осуществления рекламной деятельности.</li>
    </ol>
    <h3 className={style.item}>СПОСОБЫ И&nbsp;СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</h3>
    <ol className={style.list}>
      <li className={style.text}>Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, 
        в&nbsp;том числе в&nbsp;информационных системах персональных данных с&nbsp;использованием средств 
        автоматизации или без использования таких средств.
      </li>
      <li className={style.text}>Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти 
        Российской Федерации только по&nbsp;основаниям и&nbsp;в&nbsp;порядке, установленным законодательством 
        Российской Федерации.
      </li>
      <li className={style.text}>При утрате или разглашении персональных данных&nbsp;ИП информирует Пользователя об&nbsp;утрате или 
        разглашении персональных данных.
      </li>
      <li className={style.text}>ИП&nbsp;принимает необходимые организационные и&nbsp;технические меры для защиты персональной информации 
        Пользователя от&nbsp;неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, 
        распространения, а&nbsp;также от&nbsp;иных неправомерных действий третьих лиц.
      </li>
      <li className={style.text}>ИП&nbsp;совместно с&nbsp;Пользователем принимает все необходимые меры по&nbsp;предотвращению убытков или 
        иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
      </li>
    </ol>
    <h3 className={style.item}>ОБЯЗАТЕЛЬСТВА СТОРОН</h3>
    <ol className={style.list}>
      <li className={style.text}>Пользователь обязан предоставить достоверные персональные данные, необходимые для пользования Сайтом.</li>
      <li className={style.text}>ИП&nbsp;обязан:</li>
      <ol className={style.list}>
        <li className={style.text}>Использовать полученную информацию исключительно для целей, указанных в&nbsp;п.&nbsp;3&nbsp;настоящей 
          Политики конфиденциальности.
        </li>
        <li className={style.text}>Обеспечить хранение конфиденциальной информации в&nbsp;тайне, не&nbsp;разглашать без предварительного 
          письменного разрешения Пользователя, а&nbsp;также не&nbsp;осуществлять продажу, обмен, опубликование, либо 
          разглашение иными возможными способами переданных персональных данных Пользователя, за&nbsp;исключением указанных выше пунктов 
          настоящей Политики Конфиденциальности.
        </li>
        <li className={style.text}>Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно 
          порядку, обычно используемого для защиты такого рода информации в&nbsp;существующем деловом обороте.
        </li>
        <li className={style.text}>Осуществить блокирование персональных данных, относящихся к&nbsp;соответствующему Пользователю, с&nbsp;момента 
          обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по&nbsp;защите 
          прав субъектов персональных данных на&nbsp;период проверки, в&nbsp;случае выявления недостоверных персональных 
          данных или неправомерных действий.
        </li>
      </ol>
    </ol>
    <h3 className={style.item}>ОТВЕТСТВЕННОСТЬ СТОРОН</h3>
    <ol className={style.list}>
      <li className={style.text}>ИП&nbsp;не&nbsp;исполнившее свои обязательства, несёт ответственность за&nbsp;убытки, понесённые Пользователем 
        в&nbsp;связи с&nbsp;неправомерным использованием персональных данных, в&nbsp;соответствии с&nbsp;законодательством 
        Российской Федерации, за&nbsp;исключением случаев, предусмотренных в&nbsp;настоящей Политики Конфиденциальности.
      </li>
      <li className={style.text}>В&nbsp;случае утраты или разглашения Конфиденциальной информации&nbsp;ИП не&nbsp;несёт ответственность, если 
        данная конфиденциальная информация:
      </li>
      <ul className={style.list}>
        <li className={style.text}>Стала публичным достоянием до&nbsp;её&nbsp;утраты или разглашения;</li>
        <li className={style.text}>Была получена от&nbsp;третьей стороны до&nbsp;момента её&nbsp;получения&nbsp;ИП;</li>
        <li className={style.text}>Была разглашена с&nbsp;согласия Пользователя;</li>
        <li className={style.text}>Была разглашена сторонними сайтами и&nbsp;сервисами, ссылки на&nbsp;использование которых присутствуют 
          на&nbsp;Сайте, в&nbsp;случае использования таких ссылок и&nbsp;сервисов Пользователем;
        </li>
      </ul>
      <li className={style.text}>Пользователь самостоятельно несет ответственность за&nbsp;возможные последствия в&nbsp;случае представления 
        недостоверных и/или неполных персональных данных.
      </li>
    </ol>
    <h3 className={style.item}>РАЗРЕШЕНИЕ СПОРОВ</h3>
    <ol className={style.list}>
      <li className={style.text}>В&nbsp;случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным 
        условием до&nbsp;обращения в&nbsp;суд является досудебный порядок в&nbsp;соответствие с&nbsp;действующим 
        законодательством.
      </li>
      <li className={style.text}>Получатель претензии в&nbsp;течение 30&nbsp;календарных дней со&nbsp;дня ее&nbsp;получения, должен 
        письменно уведомляет заявителя претензии о&nbsp;результатах рассмотрения претензии.
      </li>
      <li className={style.text}>При невозможности разрешить спор в&nbsp;добровольном порядке любая из&nbsp;Сторон вправе обратиться 
        в&nbsp;суд за&nbsp;защитой своих прав, которые предоставлены им&nbsp;действующим законодательством 
        Российской Федерации.
      </li>
    </ol>
    <h3 className={style.item}>ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h3>
    <ol className={style.list}>
      <li className={style.text}>ИП&nbsp;вправе вносить изменения в&nbsp;настоящую Политику конфиденциальности без согласия Пользователя.</li>
      <li className={style.text}>Изменения Политики конфиденциальности вступают в&nbsp;силу с&nbsp;момента их&nbsp;размещения на&nbsp;Сайте.</li>
      <li className={style.text}>Действующая Политика конфиденциальности размещена на&nbsp;Сайте по&nbsp;адресу www.les-sib-rf.ru.</li>
    </ol>
  </section>
  );
}

export default Policy;

